button {
    font-family: inherit;
    color: inherit;
    text-decoration: inherit;
}
  
.btn {
    display: inline-block;
    white-space: nowrap;
    padding: 0.75rem 1.5rem;
    font-size: 1.15rem;
    font-weight: 600;
    color: #fff;
    background: linear-gradient(90deg, #fe8fc6 0%, #fed0c7 100%);
    border-radius: 0.66rem;
    transition: all 0.2s;
    box-shadow: 0 0.5rem 1.5rem -0.5rem currentColor;
    cursor: pointer;

}

.btn:hover,
.btn:focus {
    box-shadow: 0 0 0 2px #2e2e48, 0 0 0 4px #fafafc;
}

.btn:active {
    transform: scale(0.95);
}