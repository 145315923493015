/* Header Component Styles */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

whatwedo {
    background-color: #26a4bf;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 41px 60px;
    margin-top: 25px;
}

.inner-table-img {
    aspect-ratio: 1.39;
    object-fit: auto;
    object-position: center;
    width: 100%;
    flex-grow: 1;
}

.inner-table-column-2-rows-title {
    letter-spacing: 6px;
    font: 400 30px Roboto Slab, -apple-system, Roboto, Helvetica, sans-serif;
      color: #fff;
}

.inner-table-column-2-rows-info {
    font-family: Roboto, sans-serif;
    font-weight: 200;
    margin-top: 15px;
}

.inner-table-column-2-rows-info-bold {
    font-weight: 700;
    font-size: 16;
    color: #fff;
}

.inner-table-column-2-rows-info-reg {
    font-weight: 200;
    font-size: 16;
    color: #fff;
}

