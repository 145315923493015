@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

footer {
  display: flex;
  max-width: 640px;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  color: #fff;
  font-weight: 300;
  text-align: center;
  padding: 0 20px;
  align-self: center;
  justify-content: center;
  margin-top: 50px;
}

.box-footer {
    width: 100%;
}

.footer-url {
    color: #fff;
}