@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

contact {
  display: flex;
  max-width: 640px;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  color: #fff;
  font-weight: 300;
  text-align: center;
  padding: 0 20px;
  align-self: center;
  justify-content: center;
  margin-top: 50px;
}

.contactFormHeader {
  letter-spacing: 6px;
  font: 30px/30px Roboto Slab, -apple-system, Roboto, Helvetica, sans-serif;
}

.contactFormHeaderTitle {
  font-weight: 700; 
  letter-spacing: 6px;
  color: "#fff";
}

.contact-form {
    margin-top: 15px;
}

.form-notification {
  letter-spacing: 6px;
  background: linear-gradient(90deg, #fe8fc6 0%, #fed0c7 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font: 30px/30px Roboto Slab, -apple-system, Roboto, Helvetica, sans-serif;
}

.text-field {
    margin: 10px;
}

.text-fields {
    background-color: #fff;
    max-width: 345px;
    width: 345px;
}