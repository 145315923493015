/* Header Component Styles */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

about {
    display: flex;
    max-width: 640px;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    color: #fff;
    font-weight: 300;
    text-align: center;
    padding: 0 20px;
    align-self: center;
    justify-content: center;
    margin-top: -200px;
}

.aboutTitle {
    letter-spacing: 6px;
    background: linear-gradient(90deg, #fe8fc6 0%, #fed0c7 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font: 30px/30px Roboto Slab, -apple-system, Roboto, Helvetica, sans-serif;
}

.aboutTitleMain {
    font-weight: 700; 
    letter-spacing: 6px;
}

.aboutTitleSub {
    font-weight: 700; 
    font-size: 16px; 
    color: rgba(255,255,255,1);
}

.aboutInfo {
    font-family: Roboto, sans-serif;
    align-self: stretch;
    margin-top: 30px;
    width: 100%;
}
@media (max-width: 991px) {
    .aboutInfo {
        max-width: 100%;
    }
}

.aboutInfoTxt {
    font-weight: 700;
    letter-spacing: 6px;
    background: linear-gradient(90deg, #fe8fc6 0%, #fed0c7 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.aboutButton {
    font-family: Roboto Slab, sans-serif;
    background: linear-gradient(90deg, #fe8fc6 0%, #fed0c7 100%);
    margin-top: 10px;
    font-weight: 700;
    justify-content: center;
    padding: 17px 29px;
    cursor: pointer;
}
@media (max-width: 991px) {
    .aboutButton {
      margin-top: 10px;
      padding: 0 20px;
    }
}