/* Header Component Styles */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

modules {
    display: flex;
    max-width: 640px;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    color: #fff;
    font-weight: 300;
    text-align: center;
    padding: 0 20px;
    align-self: center;
    justify-content: center;
    margin-top: 30px;
}

.modules-main-img {
    aspect-ratio: 1.39;
    object-fit: auto;
    object-position: center;
    width: 100%;
    flex-grow: 1;
}

.modules-title {
    letter-spacing: 6px;
    font: 400 30px Roboto Slab, -apple-system, Roboto, Helvetica, sans-serif;
      color: #fff;
}

.list-item-text {
    font-weight: 200;
    font-size: 16;
    color: #fff;
}


