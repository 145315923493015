hero {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    min-height: 587px;
    color: #fff;
    text-align: center;
}

.mainImg {
    position: absolute;
    inset: 0;
    height: 60%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.heroInner {
    display: flex;
    background: linear-gradient(
        180deg,
        rgba(48, 51, 78, 0.5) 0%,
        rgba(90, 92, 114, 0.5) 100%
    );
    width: 418px;
    max-width: 100%;
    margin: 0 auto;
    align-self: center;
    justify-content: center;
    z-index: 10;
}

span {
    margin: 5px;
    max-width: 100%;
}

h1 {
    letter-spacing: 6px;
    font: 600 30px Roboto Slab, -apple-system, Roboto, Helvetica, sans-serif;
    color: #fff;
    margin-bottom: 1.5rem;
}

h4 {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    margin-top: 21px;
    color: #fff;
    align-self: center;
    justify-content: center;
}
