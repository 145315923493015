/* Header Component Styles */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

services {
  display: flex;
  max-width: 640px;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  color: #fff;
  font-weight: 300;
  text-align: center;
  padding: 0 20px;
  align-self: center;
  justify-content: center;
  margin-top: 50px;
}

.serviceHeader {
  letter-spacing: 6px;
  background: linear-gradient(90deg, #fe8fc6 0%, #fed0c7 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font: 30px/30px Roboto Slab, -apple-system, Roboto, Helvetica, sans-serif;
}

.serviceHeaderTitle {
  font-weight: 700; 
  letter-spacing: 6px;
}

.serviceHeaderSubTitle {
  font-weight: 700; 
  font-size: 16px; 
  color: rgba(255,255,255,1);
}

.box-cards {
  margin-top: 15px;
}

.card-action-area {
  background-color: "#30334E",
}

.card-content-title {
  letter-spacing: 6px;
  background: linear-gradient(90deg, #fe8fc6 0%, #fed0c7 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font: 30px/30px Roboto Slab, -apple-system, Roboto, Helvetica, sans-serif;
}